'use client';

import './phone-auth-select.scss';

import cx from 'classix';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { useTranslations } from 'next-intl';
import {
  type ChangeEvent,
  type ClipboardEvent,
  type KeyboardEvent,
} from 'react';
import {
  useRef,
  useState,
} from 'react';
import Select, { type SingleValue } from 'react-select';
import { useOnClickOutside } from 'usehooks-ts';

import PhoneCodeInput from '@/features/auth/components/phone-auth/phone-code-input/phone-code-input';
import PhoneCodeOption from '@/features/auth/components/phone-auth/phone-code-option/phone-code-option';
import PhoneCodeValue from '@/features/auth/components/phone-auth/phone-code-value/phone-code-value';
import getCheckPhone from '@/features/auth/requests/get-check-phone';
import getValidatePhone from '@/features/auth/requests/get-validate-phone';
import { type PhoneAuthStep } from '@/features/auth/types/auth-types';
import { type PhoneSignUp } from '@/features/auth/types/check-phone-types';
import { COUNTRIES_WITH_PHONE_CODE } from '@/features/place/constants/contries-with-phone-code';
import { type CountryWithPhoneCode } from '@/features/place/types/place-types';
import Button from '@/ui/button/button';

import styles from './phone-enter-form.module.scss';

interface PhoneEnterFormProps {
  phoneNumber: string,
  setPhoneNumber: (phoneNumber: string) => void,
  setPhoneAuthStep: (phoneAuthStep: PhoneAuthStep) => void,
  setCountryWithPhoneCode: (countryWithPhoneCode: CountryWithPhoneCode) => void,
  countryWithPhoneCode: CountryWithPhoneCode,
  setSignUpData: (phoneSignUpData: PhoneSignUp) => void,
}

export default function PhoneEnterForm(props: PhoneEnterFormProps) {
  const {
    countryWithPhoneCode,
    phoneNumber,
    setCountryWithPhoneCode,
    setPhoneAuthStep,
    setPhoneNumber,
    setSignUpData,
  } = props;

  const translationsActions = useTranslations('global.actions');
  const translationsAuth = useTranslations('auth');

  const phoneInputFieldRef = useRef<HTMLInputElement>(null);
  const phoneInputRef = useRef<HTMLDivElement>(null);

  const [isPhoneInputFocused,
    setIsPhoneInputFocused] = useState<boolean>(true);

  const [error,
    setError] = useState<'too-many-attempts' | null>(null);

  const [phoneNumberIsInvalid,
    setPhoneNumberIsInvalid] = useState<boolean>(false);

  const [isCheckPhoneLoading,
    setIsCheckPhoneLoading] = useState<boolean>(false);

  const getSelectLabelValue = (option: CountryWithPhoneCode) => `${option.phoneCode.replace(' ', '')} ${option.shortName}`;

  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const digitPattern = /^\d*$/;

    if (digitPattern.test(event.target.value)) {
      setPhoneNumberIsInvalid(false);
      setError(null);
      setPhoneNumber(event.target.value);
    }
  };

  const handlePhoneNumberPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    const digitWithPlusPattern = /^\+\d*$/;
    const pastedValue = event.clipboardData.getData('text');

    // if pasted number without plus event will be handled by handlePhoneNumberChange
    if (digitWithPlusPattern.test(pastedValue)) {
      event.preventDefault();

      setPhoneNumberIsInvalid(false);
      setError(null);

      const phoneWithoutCode = pastedValue.replace(countryWithPhoneCode.phoneCode, '');

      setPhoneNumber(phoneWithoutCode);
    }
  };

  const handlePhoneInputFocus = () => {
    setIsPhoneInputFocused(true);
    phoneInputFieldRef.current?.focus();
  };

  const handlePhoneInputBlur = () => {
    setIsPhoneInputFocused(false);
  };

  const handlePhoneInputClick = () => {
    handlePhoneInputFocus();
  };

  const onChangeSelect = (selectedCountryWithPhoneCode: SingleValue<CountryWithPhoneCode>) => {
    if (selectedCountryWithPhoneCode) {
      setCountryWithPhoneCode(selectedCountryWithPhoneCode);
      handlePhoneInputFocus();
    }
  };

  const handleContinueClick = () => {
    try {
      setPhoneNumberIsInvalid(false);
      setError(null);

      const phoneUtil = PhoneNumberUtil.getInstance();
      const number = phoneUtil.parseAndKeepRawInput(phoneNumber, countryWithPhoneCode.shortName);

      if (phoneUtil.isValidNumber(number)) {
        setIsCheckPhoneLoading(true);
        const fullNumber = countryWithPhoneCode.phoneCode + phoneNumber;

        getValidatePhone(fullNumber, countryWithPhoneCode.shortName)
          .then((validatePhoneData) => {
            if (validatePhoneData.errorId === 'too-many-attempts') {
              setError('too-many-attempts');
              setIsCheckPhoneLoading(false);
              return;
            }

            getCheckPhone(fullNumber)
              .then((checkPhoneData) => {
                if (!checkPhoneData.isPhoneRegistered) {
                  setSignUpData(checkPhoneData);
                }

                setPhoneAuthStep('code');
              })
              .catch(() => null)
              .finally(() => {
                setIsCheckPhoneLoading(false);
              });
          }).catch(() => null);
      } else {
        setPhoneNumberIsInvalid(true);
      }
    } catch {
      setPhoneNumberIsInvalid(true);
      handlePhoneInputFocus();
    }
  };

  const handleInputEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;

    if (key === 'Enter') {
      handleContinueClick();
    }
  };

  useOnClickOutside(phoneInputRef, handlePhoneInputBlur);

  return (
    <form className={styles.phoneEnterForm_form}>
      <p className={styles.phoneEnterForm_formLabel}>Phone number</p>

      <div className={styles.phoneEnterForm_inputGroup}>
        <Select<CountryWithPhoneCode, false>
          instanceId="phoneCodeSelect"
          inputId="phoneCodeSelect"
          className="phoneCodeSelect"
          classNamePrefix="phoneCodeSelect"
          getOptionValue={getSelectLabelValue}
          value={countryWithPhoneCode}
          onChange={onChangeSelect}
          name="countryCode"
          components={{
            DropdownIndicator: null,
            IndicatorSeparator: null,
            Input: PhoneCodeInput,
            Option: PhoneCodeOption,
            SingleValue: PhoneCodeValue,
          }}
          options={COUNTRIES_WITH_PHONE_CODE}
          isDisabled={isCheckPhoneLoading}
        />

        <div
          ref={phoneInputRef}
          role="presentation"
          onClick={handlePhoneInputClick}
          className={cx(
            styles.phoneEnterForm_phoneInput,
            isPhoneInputFocused && styles.phoneEnterForm_phoneInput__isFocused,
            phoneNumberIsInvalid && styles.phoneEnterForm_phoneInput__isInvalid,
            isCheckPhoneLoading && styles.phoneEnterForm_phoneInput__isDisabled,
          )}
        >
          <p className={cx(styles.phoneEnterForm_code)}>
            {countryWithPhoneCode.phoneCode}
          </p>

          {/* eslint-disable jsx-a11y/no-autofocus */}
          <input
            ref={phoneInputFieldRef}
            className={cx(
              styles.phoneEnterForm_inputField,
            )}
            disabled={isCheckPhoneLoading}
            id="phone"
            autoComplete="off"
            onBlur={handlePhoneInputBlur}
            onFocus={handlePhoneInputFocus}
            autoFocus
            type="tel"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            onPaste={handlePhoneNumberPaste}
            onKeyDown={handleInputEnter}
          />
        </div>

        {phoneNumberIsInvalid && (
          <p className={styles.phoneEnterForm_errorText}>
            {translationsAuth('invalid-phone')}
          </p>
        )}

        {error && (
          <p className={styles.phoneEnterForm_errorText}>
            {translationsAuth('too-many-attempts')}
          </p>
        )}
      </div>

      <div className={styles.phoneEnterForm_continue}>
        <Button
          element="button"
          variant="outlined"
          size="lg"
          fullWidth
          onClick={handleContinueClick}
          withFocus
          isLoading={isCheckPhoneLoading}
        >
          {translationsActions('continue')}
        </Button>
      </div>
    </form>
  );
}
