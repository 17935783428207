import authDataAdapter from '@/features/auth/requests/adapters/auth-data-adapter';
import { type AuthDataResponse } from '@/features/auth/types/auth-resopnse-type';
import {
  type AuthData,
  type PhoneSignUpData,
} from '@/features/auth/types/auth-types';
import { type FetchResponse } from '@/shared/types/fetch-response-types';

export default async function postPhoneSignUp(phoneSignUpData: PhoneSignUpData): Promise<FetchResponse<AuthData | null, null>> {
  const headers: HeadersInit = { 'Content-Type': 'application/json' };

  const phoneSignUoResponse = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/v1/AccountPhone`,
    {
      body: JSON.stringify(phoneSignUpData),
      cache: 'no-store',
      headers,
      method: 'POST',
    },
  );

  if (!phoneSignUoResponse.ok) {
    throw new Error('Validate phone failed');
  }

  const data = await phoneSignUoResponse.json() as AuthDataResponse;

  return { data: authDataAdapter(data) };
}
