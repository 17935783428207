import { type CheckPhoneResponse } from '@/features/auth/types/check-phone-respoonse-types';
import { type CheckPhone } from '@/features/auth/types/check-phone-types';

export default function checkPhoneAdapter(checkPhoneResponse: CheckPhoneResponse): CheckPhone {
  if ('signup_fields' in checkPhoneResponse) {
    return {
      isPhoneRegistered: false,
      signupFields: checkPhoneResponse.signup_fields,
    };
  }

  return {
    authOptions: checkPhoneResponse.auth_options,
    isPhoneRegistered: true,
  };
}
