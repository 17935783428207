import { type OptionProps } from 'react-select';
import { components } from 'react-select';

// import styles from '@/features/currency/components/currency-picker/currency-picker.module.scss';
import { type CountryWithPhoneCode } from '@/features/place/types/place-types';

export default function PhoneCodeOption(props: OptionProps<CountryWithPhoneCode>) {
  const { data } = props;

  return (
    <components.Option {...props}>
      {`(${data.phoneCode}) ${data.shortName}`}
    </components.Option>
  );
}
