'use client';

import './input-birth-date.scss';

import { useTranslations } from 'next-intl';
import {
  useEffect,
  useRef,
} from 'react';
import Select, {
  type SelectInstance,
  type SingleValue,
} from 'react-select';
import { useIsMounted } from 'usehooks-ts';

import styles from '@/features/user/components/user-birthday-form/user-birthday-form.module.scss';
import {
  type UserBirthday,
  type UserBirthdayInputName,
} from '@/features/user/types/user-profile-types';

export interface DateOption {
  label: number;
  value: number;
}

type Months = Record<number, DateOption[]>;

const monthsDays: Months = {
  1: Array.from({ length: 31 }, (_, index) => ({ label: index + 1, value: index + 1 })), // January
  2: Array.from({ length: 28 }, (_, index) => ({ label: index + 1, value: index + 1 })), // February (non-leap year)
  3: Array.from({ length: 31 }, (_, index) => ({ label: index + 1, value: index + 1 })), // March
  4: Array.from({ length: 30 }, (_, index) => ({ label: index + 1, value: index + 1 })), // April
  5: Array.from({ length: 31 }, (_, index) => ({ label: index + 1, value: index + 1 })), // May
  6: Array.from({ length: 30 }, (_, index) => ({ label: index + 1, value: index + 1 })), // June
  7: Array.from({ length: 31 }, (_, index) => ({ label: index + 1, value: index + 1 })), // July
  8: Array.from({ length: 31 }, (_, index) => ({ label: index + 1, value: index + 1 })), // August
  9: Array.from({ length: 30 }, (_, index) => ({ label: index + 1, value: index + 1 })), // September
  10: Array.from({ length: 31 }, (_, index) => ({ label: index + 1, value: index + 1 })), // October
  11: Array.from({ length: 30 }, (_, index) => ({ label: index + 1, value: index + 1 })), // November
  12: Array.from({ length: 31 }, (_, index) => ({ label: index + 1, value: index + 1 })), // December
};

const months: DateOption[] = Array.from({ length: 12 }, (_, index) => ({ label: index + 1, value: index + 1 }));

const currentYear = new Date().getFullYear();
const years: { label: number; value: number }[] = Array.from({ length: 103 }, (_, index) => ({
  label: currentYear - 18 - index,
  value: currentYear - 18 - index,
}));

// Adjust February days for leap years
const adjustForLeapYear = (year: number): void => {
  if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
    // Set February to 29 days
    monthsDays[2] = Array.from(
      { length: 29 },
      (_, index) => ({ label: index + 1, value: index + 1 }),
    );
  }
};

adjustForLeapYear(currentYear);

interface InputBirthDateProps {
  birthDate: Partial<UserBirthday> | null;
  onBirthDateChange: (inputName: UserBirthdayInputName, value: number) => void;
  autoFocus?: boolean;
  isDisabled?: boolean;
}

export default function InputBirthDate(props: InputBirthDateProps) {
  const {
    autoFocus,
    birthDate,
    isDisabled,
    onBirthDateChange,
  } = props;

  const translationsDates = useTranslations('global.date');

  const isMounted = useIsMounted();

  const selectedYear = years.find((year) => year.value === birthDate?.birth_year);
  const selectedMonth = months.find((month) => month.value === birthDate?.birth_month);
  const selectedDay = birthDate?.birth_month ? monthsDays[birthDate.birth_month].find((day) => day.value === birthDate.birth_day) : undefined;

  const daysSelectRef = useRef<SelectInstance<DateOption>>(null);
  const monthsSelectRef = useRef<SelectInstance<DateOption>>(null);
  const yearsSelectRef = useRef<SelectInstance<DateOption>>(null);

  const handleYearSelectChange = (option: SingleValue<DateOption>) => {
    if (option) {
      onBirthDateChange('birth_year', option.value);
      monthsSelectRef.current?.focus();
    }
  };

  const handleMonthsSelectChange = (option: SingleValue<DateOption>) => {
    if (option) {
      const maxDaysInMonth = monthsDays[option.value].length + 1;

      if (selectedDay?.value && maxDaysInMonth < selectedDay.value) {
        onBirthDateChange('birth_day', maxDaysInMonth);
      }

      onBirthDateChange('birth_month', option.value);
      daysSelectRef.current?.focus();
    }
  };

  const handleDaySelectChange = (option: SingleValue<DateOption>) => {
    if (option) {
      onBirthDateChange('birth_day', option.value);
    }
  };

  useEffect(() => {
    if (autoFocus) {
      yearsSelectRef.current?.focus();
    }
  }, [autoFocus, isMounted]);

  return (
    <div className={styles.birthdayForm_birthdayFields}>
      <div className={styles.birthdayForm_birthdayInput}>
        <div className={styles.birthdayForm_birthdayLabel}>
          {translationsDates('year')}
        </div>

        <Select
          className="birthdaySelect"
          classNamePrefix="birthdaySelect"
          ref={yearsSelectRef}
          value={selectedYear}
          onChange={handleYearSelectChange}
          name="birth_year"
          options={years}
          components={{ IndicatorsContainer: () => null }}
          isDisabled={isDisabled}
          placeholder={null}
        />
      </div>

      <div className={styles.birthdayForm_birthdayInput}>
        <div className={styles.birthdayForm_birthdayLabel}>
          {translationsDates('month')}
        </div>

        <Select
          className="birthdaySelect"
          classNamePrefix="birthdaySelect"
          ref={monthsSelectRef}
          value={selectedMonth}
          onChange={handleMonthsSelectChange}
          name="birth_month"
          options={months}
          components={{ IndicatorsContainer: () => null }}
          isDisabled={isDisabled}
          placeholder={null}
        />
      </div>

      <div className={styles.birthdayForm_birthdayInput}>
        <div className={styles.birthdayForm_birthdayLabel}>
          {translationsDates('day')}
        </div>

        <Select
          className="birthdaySelect"
          classNamePrefix="birthdaySelect"
          ref={daysSelectRef}
          value={selectedDay}
          onChange={handleDaySelectChange}
          name="birth_day"
          options={monthsDays[selectedMonth?.value ?? 1]}
          components={{ IndicatorsContainer: () => null }}
          isDisabled={isDisabled}
          placeholder={null}
        />
      </div>
    </div>
  );
}
