import { type CurrencySymbols } from '@/features/currency/types/currency-types';
import { type Language } from '@/features/language/types/language-types';
import { type UserIdVerificationDetails } from '@/features/user/types/user-id-verification-types';
import { type ImageThumbnail } from '@/shared/types/image-thumbnail-types';

export enum UserVerifications {
  Email = 'Email',
  Phone = 'Text',
  LinkedIn = 'LinkedIn',
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  Apple = 'Apple',
  Google = 'Google',
}

export interface User {
  avatar: ImageThumbnail,
  photos: ImageThumbnail[] | null,
  birthDate: Date,
  id: number,
  firstName: string,
  lastName: string,
  age: number,
  gender: GENDERS_KEYS | null,
  isPaidAccount: boolean,
  spokenLanguages: string[] | null,
  about: string | null,
  verified: boolean,
  phoneData: UserContactPhone | null,
  verifications: {
    email?: boolean,
    phone?: boolean,
    facebook?: boolean,
    instagram?: boolean,
    linkedIn?: boolean,
    apple?: boolean,
    google?: boolean,
    id?: UserIdVerificationDetails | null,
  },
}

export enum GENDERS_KEYS {
  Female = 2,
  Male = 4,
}

export type Gender = 'female' | 'male';

export interface UserLocalizationSettings {
  locale: Language['locale'],
  currency: CurrencySymbols['name'],
}

interface UserContactPhone {
  countryCode: string,
  countryId: number,
  isVisible: boolean,
  phone: string,
}
