import cx from 'classix';
import {
  type ClassAttributes,
  type InputHTMLAttributes,
} from 'react';

import styles from './input-text.module.scss';

interface InputTextProps extends ClassAttributes<HTMLInputElement>, InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  isError?: boolean;
}

export default function InputText(props: InputTextProps) {
  const {
    disabled,
    isError,
    label,
    onChange,
    value,
  } = props;

  return (
    <div
      className={cx(
        styles.inputText,
        isError && styles.inputText__isError,
      )}
    >
      {label && (
        <p className={styles.inputText_label}>
          {label}
        </p>
      )}

      <input
        className={styles.inputText_input}
        type="text"
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  );
}
