import { type Photo } from '@/features/listing/types/listings-search-response-types';
import { type ImageThumbnail } from '@/shared/types/image-thumbnail-types';

export default function photoAdapter(photo: Photo): ImageThumbnail {
  const {
    id,
    path,
    path_medium,
    path_small,
  } = photo;

  return {
    id,
    path,
    pathMedium: path_medium,
    pathSmall: path_small,
  };
}
