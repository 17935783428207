import { type CountryWithPhoneCode } from '@/features/place/types/place-types';

interface GetValidatePhoneResponse {
  errorId: null | string;
  data: null | string ;
}

export default async function getValidatePhone(phoneNumber: string, phoneLocale: CountryWithPhoneCode['shortName']): Promise<GetValidatePhoneResponse> {
  const phoneValidateResponse = await fetch(
    `${process.env.NEXT_PUBLIC_API_URL}/v1/ValidatePhone?phone_number=${phoneNumber}&locale=${phoneLocale}`,
    { cache: 'no-store' },
  );

  // Too Many Requests (429) if user is trying to validate phone too many times
  if (phoneValidateResponse.status === 429 || phoneValidateResponse.status === 400) {
    return { data: null, errorId: 'too-many-attempts' };
  }

  if (!phoneValidateResponse.ok) {
    throw new Error('Validate phone failed');
  }

  const phoneValidateData = await phoneValidateResponse.json() as string;

  return { data: phoneValidateData, errorId: null };
}
