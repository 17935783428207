'use client';

import Intercom, {
  show,
  shutdown,
} from '@intercom/messenger-js-sdk';
import cx from 'classix';
import Image from 'next/image';
import { useTranslations } from 'next-intl';
import {
  type ReactNode,
  useEffect,
  useState,
} from 'react';
import {
  useIsClient,
  useScreen,
} from 'usehooks-ts';

import PhoneEnterForm from '@/features/auth/components/phone-auth/phone-enter-form/phone-enter-form';
import PhoneSignUpForm from '@/features/auth/components/phone-auth/phone-sign-up-form/phone-sign-up-form';
import ValidationCodeForm from '@/features/auth/components/phone-auth/validation-code-form/validation-code-form';
import { type PhoneAuthStep } from '@/features/auth/types/auth-types';
import { type PhoneSignUp } from '@/features/auth/types/check-phone-types';
import {
  COUNTRIES_WITH_PHONE_CODE,
  DEFAULT_COUNTRIES_WITH_PHONE_CODE,
} from '@/features/place/constants/contries-with-phone-code';
import { type CountryWithPhoneCode } from '@/features/place/types/place-types';
import useAppFlyerLink from '@/features/roomster-app/hooks/use-app-flyer-link';
import { Link } from '@/i18n/routing';
import APP_CONFIG from '@/shared/configs/app-config';
import BREAKPOINTS from '@/shared/constants/breackpoints';
import Button from '@/ui/button/button';

import styles from './phone-auth.module.scss';

interface PhoneAuthProps {
  userCountryCode: string
}

export default function PhoneAuth(props: PhoneAuthProps) {
  const { userCountryCode } = props;

  const isClient = useIsClient();
  const screen = useScreen();
  const loginPageAppFlyerLink = useAppFlyerLink('mobile', 'mobileLoginPage');
  const isMobile = isClient && screen.width < BREAKPOINTS.MOBILE_SCREEN_WIDTH;

  const translationsAuth = useTranslations('auth');
  const translationsApp = useTranslations('roomster-app.app-download');

  const defaultCountryWithPhoneCode = COUNTRIES_WITH_PHONE_CODE.find((countryWithPhoneCode) => countryWithPhoneCode.shortName === userCountryCode)
    ?? DEFAULT_COUNTRIES_WITH_PHONE_CODE;

  const [isAppDownloadDisplayed,
    setIsAppDownloadDisplayed] = useState<boolean>(isMobile && !APP_CONFIG.isMobileAuthEnabled);

  const [countryWithPhoneCode,
    setCountryWithPhoneCode] = useState<CountryWithPhoneCode>(defaultCountryWithPhoneCode);

  const [phoneNumber,
    setPhoneNumber] = useState<string>('');

  const [phoneAuthStep,
    setPhoneAuthStep] = useState<PhoneAuthStep>('phone');

  const [signUpData,
    setSignUpDataData] = useState<PhoneSignUp | null>(null);

  const handleSupportClick = () => {
    show();
  };

  const renderSupportContact = (chunks: ReactNode) => (
    <div className={styles.phoneAuth_supportButton}>
      <Button
        element="button"
        variant="ghost"
        onClick={handleSupportClick}
      >
        {chunks}
      </Button>
    </div>
  );

  useEffect(() => {
    if (!APP_CONFIG.isMobileAuthEnabled && isMobile) {
      setIsAppDownloadDisplayed(true);
      shutdown();
    } else {
      setIsAppDownloadDisplayed(false);
      Intercom({ app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID });
    }

    return () => {
      shutdown();
    };
  }, [isMobile]);

  // when we check if the phone number is registered, and we didn't get signup data,
  // that means the phone number is registered, and we set isPhoneRegistered to true
  const isPhoneRegistered = signUpData?.isPhoneRegistered ?? true;

  return isAppDownloadDisplayed ? (
    // eslint-disable-next-line canonical/prefer-react-lazy
    <Link href={loginPageAppFlyerLink}>
      <div className={styles.appDownload}>
        <Image src="/images/roomster-logo/logo.svg" width={42} height={42} alt="roomster logo" />

        <p className={styles.appDownload_text}>
          {translationsApp('find')}
        </p>

        <p className={styles.appDownload_download}>
          {translationsApp('download')}
        </p>

        <Image loading="eager" className={styles.app_image} src="/images/roomster-app/app-phones.png" alt="roomster app" width="288" height="411" />
      </div>
    </Link>
  ) : (
    <div className={styles.phoneAuth}>
      {phoneAuthStep === 'phone' && (
        <div className={styles.phoneAuth_box}>
          <h2 className={styles.phoneAuth_title}>
            {translationsAuth('enter-mobile')}
          </h2>

          <p className={styles.phoneAuth_subTitle}>
            {translationsAuth('enter-mobile-detailed')}
          </p>

          <p className={styles.phoneAuth_subTitle}>
            {translationsAuth('voip-not-accepted')}
          </p>

          <div className={cx(styles.phoneAuth_form, styles.phoneAuth_formPhone)}>
            <PhoneEnterForm
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              setPhoneAuthStep={setPhoneAuthStep}
              setCountryWithPhoneCode={setCountryWithPhoneCode}
              countryWithPhoneCode={countryWithPhoneCode}
              setSignUpData={setSignUpDataData}
            />
          </div>

          <div className={styles.phoneAuth_support}>
            {translationsAuth.rich('support-contact', { link: (chunks) => renderSupportContact(chunks) })}
          </div>
        </div>
      )}

      {phoneAuthStep === 'code' && (
        <div className={styles.phoneAuth_box}>
          <h2 className={styles.phoneAuth_title}>
            {translationsAuth('enter-code')}
          </h2>

          <p className={styles.phoneAuth_sentNumber}>
            <span>
              {translationsAuth('code-sent')}
            </span>

            <span>
              {countryWithPhoneCode.phoneCode}
              {phoneNumber}
            </span>
          </p>

          <div className={styles.phoneAuth_form}>
            <ValidationCodeForm
              phoneNumber={countryWithPhoneCode.phoneCode + phoneNumber}
              setPhoneAuthStep={setPhoneAuthStep}
              isPhoneRegistered={isPhoneRegistered}
            />
          </div>

          <div className={styles.phoneAuth_support}>
            {translationsAuth.rich('support-contact', { link: (chunks) => renderSupportContact(chunks) })}
          </div>
        </div>
      )}

      {phoneAuthStep === 'signup' && (
        <div className={cx(styles.phoneAuth_box, styles.phoneAuth_signUp)}>
          <h2 className={styles.phoneAuth_title}>
            {translationsAuth('create-account')}
          </h2>

          <div>
            <PhoneSignUpForm phoneNumber={countryWithPhoneCode.phoneCode + phoneNumber} />
          </div>
        </div>
      )}
    </div>
  );
}
