import { useTranslations } from 'next-intl';
import { type ChangeEvent } from 'react';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import postPhoneSignUp from '@/features/auth/requests/post-phone-sign-up';
import { type PhoneSignUpData } from '@/features/auth/types/auth-types';
import { getKeywordCookie } from '@/features/roomster-app/cookies/cookie-keyword';
import { getReferralCookie } from '@/features/roomster-app/cookies/cookie-referral';
import { setUserBearerCookie } from '@/features/user/cookies/cookie-user-bearer';
import {
  type UserBirthday,
  type UserBirthdayInputName,
} from '@/features/user/types/user-profile-types';
import { type GENDERS_KEYS } from '@/features/user/types/user-types';
import { useRouter } from '@/i18n/routing';
import { ROUTER_LINKS } from '@/shared/constants/links-constants';
import Button from '@/ui/button/button';
import InputBirthDate from '@/ui/form-elements/input-birth-date/input-birth-date';
import InputGender from '@/ui/form-elements/input-gender/input-gender';
import InputText from '@/ui/form-elements/input-text/input-text';

import styles from './phone-sign-up-form.module.scss';

interface InputData<T> {
  error: string | null;
  value: T;
}

interface PhoneSignUpFormProps {
  phoneNumber: string;
}

export default function PhoneSignUpForm(props: PhoneSignUpFormProps) {
  const { phoneNumber } = props;

  const translationsActions = useTranslations('global.actions');
  const translationsFromErrors = useTranslations('global.form.errors');
  const translationsUser = useTranslations('user');

  const router = useRouter();

  const onlyLettersPattern = /^[\sA-Za-z]*$/;

  const [firstName,
    setFirstName] = useState<InputData<string>>({ error: null, value: '' });

  const [lastName,
    setLastName] = useState<InputData<string>>({ error: null, value: '' });

  const [gender,
    setGender] = useState<InputData<GENDERS_KEYS | 'Unknown' | null>>({ error: null, value: null });

  const [birthDate,
    setBirthDate] = useState<InputData<Partial<UserBirthday> | null>>({ error: null, value: null });

  const [recaptcha,
    setRecaptcha] = useState<InputData<boolean>>({ error: null, value: false });

  const [isLoading,
    setIsLoading] = useState<boolean>(false);

  const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value.length > 50) {
      setFirstName({
        error: translationsFromErrors('max-length', { length: 50 }),
        value: firstName.value,
      });
    } else if (onlyLettersPattern.test(value)) {
      setFirstName({
        error: null,
        value,
      });
    }
  };

  const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value.length > 50) {
      setLastName({
        error: translationsFromErrors('max-length', { length: 50 }),
        value: lastName.value,
      });
    } else if (onlyLettersPattern.test(value)) {
      setLastName({
        error: null,
        value,
      });
    }
  };

  const handleGenderChange = (value: GENDERS_KEYS | 'Unknown' | null) => {
    setGender({
      error: null,
      value,
    });
  };

  const handleBirthDateChange = (inputName: UserBirthdayInputName, value: number) => {
    if (birthDate.value) {
      setBirthDate({
        error: null,
        value: {
          ...birthDate.value,
          [inputName]: value,
        },
      });
    } else {
      setBirthDate({
        error: null,
        value: { [inputName]: value },
      });
    }
  };

  const handleRecaptchaChange = (data: string | null) => {
    if (data) {
      setRecaptcha({
        error: null,
        value: true,
      });
    } else {
      setRecaptcha({
        error: translationsFromErrors('recaptcha-failed'),
        value: false,
      });
    }
  };

  const handleSignUp = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      let isFromValid = true;

      if (!firstName.value.trim()) {
        setFirstName({ ...firstName, error: translationsFromErrors('required') });
        isFromValid = false;
      }
      if (!lastName.value.trim()) {
        setLastName({ ...lastName, error: translationsFromErrors('required') });
        isFromValid = false;
      }
      if (!gender.value) {
        setGender({ ...gender, error: translationsFromErrors('required') });
        isFromValid = false;
      }
      if (!birthDate.value?.birth_year || !birthDate.value.birth_month || !birthDate.value.birth_day) {
        setBirthDate({ ...birthDate, error: translationsFromErrors('required') });
        isFromValid = false;
      }
      if (!recaptcha.value) {
        setRecaptcha({ error: translationsFromErrors('recaptcha-required'), value: false });
        isFromValid = false;
      }

      if (isFromValid) {
        setIsLoading(true);
        // Was pick from ald app
        const HARDCODED_SERVICE_TYPE = 'Undefined';
        const referrer = getReferralCookie();
        const keyword = getKeywordCookie();

        const phoneSignUpData: PhoneSignUpData = {
          // @ts-expect-error we already checked that value is not null
          birth_day: birthDate.value.birth_day,
          // @ts-expect-error we already checked that value is not null
          birth_month: birthDate.value.birth_month,
          // @ts-expect-error we already checked that value is not null
          birth_year: birthDate.value.birth_year,
          first_name: firstName.value,
          // @ts-expect-error we already checked that value is not null
          gender: gender.value,
          last_name: lastName.value,
          referrer: referrer || '',
          service_type: HARDCODED_SERVICE_TYPE,
          validated_phone: phoneNumber,
        };

        if (keyword) {
          // eslint-disable-next-line canonical/id-match
          phoneSignUpData.ref_keyword = keyword;
        }

        const postPhoneSignUpResponse = await postPhoneSignUp(phoneSignUpData);

        if (postPhoneSignUpResponse.data) {
          router.push(ROUTER_LINKS.listingsNew);
          setUserBearerCookie(postPhoneSignUpResponse.data.accessToken);
        }
      }
    })();
  };

  return (
    <form>
      <div className={styles.phoneSignUpForm_field}>
        <InputText
          label={translationsUser('first-name')}
          value={firstName.value}
          onChange={handleFirstNameChange}
          isError={!!firstName.error}
          disabled={isLoading}
        />

        {firstName.error && (
          <p className={styles.phoneSignUpForm_error}>
            {firstName.error}
          </p>
        )}
      </div>

      <div className={styles.phoneSignUpForm_field}>
        <InputText
          label={translationsUser('last-name')}
          value={lastName.value}
          onChange={handleLastNameChange}
          isError={!!lastName.error}
          disabled={isLoading}
        />

        {lastName.error && (
          <p className={styles.phoneSignUpForm_error}>
            {lastName.error}
          </p>
        )}
      </div>

      <div className={styles.phoneSignUpForm_field}>
        <p className={styles.phoneSignUpForm_label}>
          {translationsUser('gender')}
        </p>

        <InputGender
          value={gender.value}
          onGenderSelectChange={handleGenderChange}
          isDisabled={isLoading}
        />

        {gender.error && (
          <p className={styles.phoneSignUpForm_error}>
            {gender.error}
          </p>
        )}
      </div>

      <div className={styles.phoneSignUpForm_field}>
        <p className={styles.phoneSignUpForm_label}>Birthday</p>

        <InputBirthDate
          birthDate={birthDate.value}
          onBirthDateChange={handleBirthDateChange}
          isDisabled={isLoading}
        />

        {birthDate.error && (
          <p className={styles.phoneSignUpForm_error}>
            {birthDate.error}
          </p>
        )}
      </div>

      <div className={styles.phoneSignUpForm_recaptcha}>
        <ReCAPTCHA
          sitekey={process.env.NEXT_PUBLIC_GOOGLE_SITE_KEY}
          onChange={handleRecaptchaChange}
        />

        {recaptcha.error && (
          <p className={styles.phoneSignUpForm_recaptchaError}>
            {recaptcha.error}
          </p>
        )}
      </div>

      <Button
        element="button"
        fullWidth
        size="lg"
        onClick={handleSignUp}
        isLoading={isLoading}
      >
        {translationsActions('sign-up')}
      </Button>
    </form>
  );
}
