'use client';

import cx from 'classix';
import { useTranslations } from 'next-intl';
import {
  type ChangeEvent,
  type ClipboardEvent,
  type FocusEvent,
  type KeyboardEvent,
  type MouseEvent,
  useCallback,
  useEffect,
} from 'react';
import { useState } from 'react';

import putValidatePhone from '@/features/auth/requests/put-validate-phone';
import {
  type PhoneAuthStep,
  type PutValidatePhoneErrorId,
} from '@/features/auth/types/auth-types';
import { getCookieCurrentPlace } from '@/features/place/cookies/cookie-current-place';
import { setUserBearerCookie } from '@/features/user/cookies/cookie-user-bearer';
import prepareLabelForUrl from '@/helpers/prepare-label-for-url';
import { useRouter } from '@/i18n/routing';
import { ROUTER_LINKS } from '@/shared/constants/links-constants';
import { SECONDS } from '@/shared/constants/time-constants';
import useCountdown from '@/shared/hooks/use-countdown';
import { type FailedFetchResponse } from '@/shared/types/fetch-response-types';
import Button from '@/ui/button/button';
import Loader from '@/ui/loader/loader';

import styles from './validation-code.module.scss';

interface ValidationCodeFormProps {
  phoneNumber: string;
  setPhoneAuthStep: (phoneAuthStep: PhoneAuthStep) => void;
  isPhoneRegistered: boolean;
}

export default function ValidationCodeForm(props: ValidationCodeFormProps) {
  const {
    isPhoneRegistered,
    phoneNumber,
    setPhoneAuthStep,
  } = props;

  const router = useRouter();

  const [validationCode,
    setValidationCode] = useState<string[]>(['', '', '', '', '']);

  const translationsAuth = useTranslations('auth');

  const expireCodeCountdown = useCountdown({ initialSeconds: 5 * SECONDS.MINUTE });
  const resendCodeCountdown = useCountdown({ initialSeconds: SECONDS.MINUTE / 2 });

  const codeInputLength = 5;

  const [isCodeCountdownDisplay,
    setIsCodeCountdownDisplay] = useState<boolean>(false);

  const [validatePhoneError,
    setValidatePhoneError] = useState<FailedFetchResponse<PutValidatePhoneErrorId> | null>(null);

  const [isCodeExpired,
    setIsCodeExpired] = useState<boolean>(false);

  const [isLoading,
    setIsLoading] = useState<boolean>(false);

  const focusFirstInput = () => {
    const firstInput = document.querySelector('[data-number="0"]');

    if (firstInput) {
      (firstInput as HTMLInputElement).focus();
    }
  };

  const sendCode = useCallback(() => {
    setIsLoading(true);
    const code = validationCode.join('');

    putValidatePhone(phoneNumber, code, isPhoneRegistered)
      .then((authResponse) => {
        // if user register
        if (authResponse.data) {
          setUserBearerCookie(authResponse.data.accessToken);

          if (authResponse.data.user.verified) {
            const currentPlaceCookie = getCookieCurrentPlace();

            if (currentPlaceCookie) {
              router.push(`/${currentPlaceCookie.listingType}/${prepareLabelForUrl(currentPlaceCookie.fullName)}`);
            } else {
              router.push(ROUTER_LINKS.accountListings);
            }
          } else {
            router.push(ROUTER_LINKS.verification);
          }
        }

        // if user not register
        if (!isPhoneRegistered && !authResponse.isError) {
          setPhoneAuthStep('signup');
        }

        if (authResponse.isError) {
          setValidatePhoneError(authResponse);
          setValidationCode(['', '', '', '', '']);
          focusFirstInput();
          setIsLoading(false);
        }
      })
      .catch(() => null);
  }, [phoneNumber, isPhoneRegistered, router, setPhoneAuthStep, validationCode]);

  const handleValidationCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const digitPattern = /^\d*$/;

    if (digitPattern.test(event.target.value)) {
      setValidatePhoneError(null);

      const inputNumber = event.target.dataset.number;

      if (inputNumber) {
        const cloneValidationCode = [...validationCode];
        // input can have value with more than one digit, so we need to pick only the last digit
        // eslint-disable-next-line unicorn/prefer-at
        cloneValidationCode[Number(inputNumber)] = event.target.value[event.target.value.length - 1];
        setValidationCode(cloneValidationCode);

        const nextInput = document.querySelector(`[data-number="${String(Number(inputNumber) + 1)}"]`);

        if (nextInput && event.target.value) {
          (nextInput as HTMLInputElement).focus();
        }
      }

      const isCodeFilled = validationCode.every((code) => code !== '' && digitPattern.test(code));

      if (isCodeFilled) {
        sendCode();
      }
    }
  };

  const handleInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    // set cursor to the end of the input, needed to pick input value of last digit
    event.target.setSelectionRange(1, 1);
  };

  const handleInputClick = (event: MouseEvent<HTMLInputElement>) => {
    // set cursor to the end of the input, needed to pick input value of last digit
    event.currentTarget.setSelectionRange(1, 1);
  };

  const handleInputDelete = (event: KeyboardEvent<HTMLInputElement>) => {
    const {
      currentTarget,
      key,
    } = event;

    if (key === 'Backspace' || key === 'Delete') {
      const inputNumber = currentTarget.dataset.number;

      if (currentTarget.value.length === 0) {
        // if input is empty, focus on previous input
        const previousInput = document.querySelector(`[data-number="${String(Number(inputNumber) - 1)}"]`);

        if (previousInput) {
          (previousInput as HTMLInputElement).focus();
        }
      } else {
        // if input is not empty, clear input value
        const cloneValidationCode = [...validationCode];
        cloneValidationCode[Number(inputNumber)] = '';
        setValidationCode(cloneValidationCode);
      }
    }
  };

  const handleCodePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    // Prevent handleValidationCodeChange
    event.preventDefault();
    const digitPattern = /^\d*$/;
    const pastedValue = event.clipboardData.getData('text');

    if (codeInputLength === pastedValue.length && digitPattern.test(pastedValue)) {
      setValidatePhoneError(null);

      const cloneValidationCode = [...validationCode];

      cloneValidationCode.forEach((_, index) => {
        cloneValidationCode[index] = pastedValue[index];
      });

      setValidationCode(cloneValidationCode);
    }
  };

  const handleClickResendCode = () => {
    setIsCodeCountdownDisplay(true);

    if (resendCodeCountdown.hasEnded) {
      setPhoneAuthStep('phone');
    }
  };

  useEffect(() => {
    if (resendCodeCountdown.hasEnded) {
      setIsCodeCountdownDisplay(false);
    }
  }, [resendCodeCountdown.hasEnded]);

  useEffect(() => {
    if (expireCodeCountdown.hasEnded) {
      setIsCodeExpired(true);
    }
  }, [expireCodeCountdown.hasEnded]);

  useEffect(() => {
    const digitPattern = /^\d*$/;
    const isCodeFilled = validationCode.every((code) => code !== '' && digitPattern.test(code));

    if (isCodeFilled) {
      sendCode();
    }
  }, [sendCode, validationCode]);

  const codeInputs: number[] = Array.from({ length: codeInputLength }, (_, index) => index);

  return (
    <>
      <form className={styles.validationCodeFrom}>
        {isCodeExpired ? (
          <p
            className={cx(
              styles.validationCodeFrom_countdown,
              styles.validationCodeFrom_countdown__isExpired,
            )}
          >
            {translationsAuth('code-is-expire')}
          </p>
        ) : (
          <p className={styles.validationCodeFrom_countdown}>
            {translationsAuth('code-will-expire', { minutes: `${expireCodeCountdown.minutes}:${expireCodeCountdown.seconds}` })}
          </p>
        )}

        {/* eslint-disable jsx-a11y/no-autofocus */}
        <div className={styles.validationCodeFrom_inputs}>
          {codeInputs.map((number) => (
            <input
              key={number}
              data-number={number}
              value={validationCode[number]}
              className={cx(
                styles.validationCodeFrom_input,
                validatePhoneError && styles.validationCodeFrom_input__isInvalid,
              )}
              type="text"
              autoComplete="off"
              autoFocus={number === 0}
              onChange={handleValidationCodeChange}
              onFocus={handleInputFocus}
              onClick={handleInputClick}
              onKeyUp={handleInputDelete}
              onPaste={handleCodePaste}
              disabled={isLoading || isCodeExpired}
            />
          ))}
        </div>

        {isLoading && (
          <div className={styles.validationCodeFrom_loader}>
            <Loader />
          </div>
        )}

        {validatePhoneError && (
          <p className={styles.validationCodeFrom_invalidCode}>
            {translationsAuth(validatePhoneError.errorId)}
          </p>
        )}
      </form>

      <div className={styles.validationCodeFrom_info}>
        <div className={styles.validationCodeFrom_resendCode}>
          <p>{translationsAuth('did-not-get-code')}</p>

          <div className={styles.validationCodeFrom_resendButton}>
            <Button
              element="button"
              variant="ghost"
              onClick={handleClickResendCode}
              disabled={isCodeCountdownDisplay}
            >
              {isCodeCountdownDisplay ? (
                <>
                  {resendCodeCountdown.minutes}
                  <span>:</span>
                  {resendCodeCountdown.seconds}
                </>
              ) : translationsAuth('resend-code')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
