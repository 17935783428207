import { type AuthDataResponse } from '@/features/auth/types/auth-resopnse-type';
import { type AuthData } from '@/features/auth/types/auth-types';
import userAdapter from '@/features/user/requests/adapters/user-adapter';

export default function authDataAdapter(authDataResponse: AuthDataResponse): AuthData {
  return {
    accessToken: authDataResponse.access_token,
    email: authDataResponse.email,
    expiresInSeconds: authDataResponse.expires_in,
    tokenType: authDataResponse.token_type,
    user: userAdapter(authDataResponse.user),
  };
}
