import { type SingleValueProps } from 'react-select';
import { components } from 'react-select';

// import styles from '@/features/currency/components/currency-picker/currency-picker.module.scss';
import { type CountryWithPhoneCode } from '@/features/place/types/place-types';

export default function PhoneCodeValue(props: SingleValueProps<CountryWithPhoneCode>) {
  const { data } = props;

  return (
    <components.SingleValue {...props}>
      {data.shortName}
    </components.SingleValue>
  );
}
