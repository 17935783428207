import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useIsMounted } from 'usehooks-ts';

export default function useCountdown({
  initialSeconds = 0,
  start = true,
}) {
  const isMounted = useIsMounted();

  const [timeLeft,
    setTimeLeft] = useState(initialSeconds);
  const [isRunning,
    setIsRunning] = useState(start);
  const [hasEnded,
    setHasEnded] = useState(false);

  useEffect(() => {
    if (!isRunning) return;

    const intervalId = setInterval(() => {
      setTimeLeft((previousTime) => {
        if (previousTime <= 1) {
          clearInterval(intervalId);
          setHasEnded(true);
          setIsRunning(false);

          return 0;
        }

        return previousTime - 1;
      });
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => { clearInterval(intervalId); };
  }, [isRunning, isMounted]);

  const resetCountdown = useCallback(() => {
    setTimeLeft(initialSeconds);
    setIsRunning(true);
    setHasEnded(false);
  }, [initialSeconds]);

  const startCountdown = useCallback((initialSecondsValue: number) => {
    setTimeLeft(initialSecondsValue);
    setIsRunning(true);
    setHasEnded(false);
  }, []);

  const minutes = String(Math.floor(timeLeft / 60)).padStart(2, '0');
  const seconds = String(timeLeft % 60).padStart(2, '0');

  return start ? {
    hasEnded,
    minutes,
    resetCountdown,
    seconds,
  } : {
    hasEnded,
    minutes,
    resetCountdown,
    seconds,
    startCountdown,
  };
}
