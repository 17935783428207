import photoAdapter from '@/features/listing/requests/adapters/photo-adapter';
import { type UserResponse } from '@/features/user/types/user-response-types';
import {
  GENDERS_KEYS,
  type User,
  UserVerifications,
} from '@/features/user/types/user-types';
import { type ImageThumbnail } from '@/shared/types/image-thumbnail-types';

export default function userAdapter(userResponse: UserResponse): User {
  const {
    age,
    contact_phone,
    description,
    dob,
    first_name,
    full_access,
    gender,
    id,
    last_name,
    photos,
    spoken_languages,
    verified,
    verified_with,
  } = userResponse;

  let userPhotos: ImageThumbnail[] | null = null;

  if (photos.length > 0) {
    userPhotos = photos.map((photo) => photoAdapter(photo));
  }

  // user ID verification came from dedicated endpoint and added to user object dynamically
  return {
    about: description,
    age,
    avatar: photoAdapter(photos[0]),
    birthDate: dob,
    firstName: first_name,
    gender: gender in GENDERS_KEYS ? gender : null,
    id,
    isPaidAccount: full_access,
    lastName: last_name,
    phoneData: contact_phone ? {
      // eslint-disable-next-line canonical/id-match
      countryCode: contact_phone.country_code,
      // eslint-disable-next-line canonical/id-match
      countryId: contact_phone.country_id,
      // eslint-disable-next-line canonical/id-match
      isVisible: contact_phone.is_visible,
      // eslint-disable-next-line canonical/id-match
      phone: contact_phone.phone,
    } : null,
    photos: userPhotos,
    spokenLanguages: spoken_languages,
    verifications: {
      // eslint-disable-next-line canonical/id-match
      apple: verified_with.includes(UserVerifications.Apple),
      // eslint-disable-next-line canonical/id-match
      email: verified_with.includes(UserVerifications.Email),
      // eslint-disable-next-line canonical/id-match
      facebook: verified_with.includes(UserVerifications.Facebook),
      // eslint-disable-next-line canonical/id-match
      google: verified_with.includes(UserVerifications.Google),
      // eslint-disable-next-line canonical/id-match
      instagram: verified_with.includes(UserVerifications.Instagram),
      // eslint-disable-next-line canonical/id-match
      linkedIn: verified_with.includes(UserVerifications.LinkedIn),
      // eslint-disable-next-line canonical/id-match
      phone: verified_with.includes(UserVerifications.Phone),
    },
    verified,
  };
}
